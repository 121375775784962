import axios from 'axios';
import API_URL from '../backend'
class FormulariosService {
    postTrabajaNosotros(form){
        /* form=new FormData();
        form.append('nombre', 'jacob'); */
        const axiosRequest = {
            method: 'post',
            url: API_URL+'/formularios/trabaja_nosotros',
            data:form,
            headers: { "Content-Type": "multipart/form-data" }
            /* processData: false,
            contentType: false, */
            
        }
        return axios(axiosRequest);
    }
}
export default new FormulariosService();