<template lang="html">
  <section
    class="co-trabaja-nosotros m-3 m-md-5 d-flex flex-wrap align-items-start"
  >
    <h2 class="col-12 text-5xl text-primary font-semibold">
      ¡Trabaja con <span class="text-900">nosotros!</span>
    </h2>
    <div
      class="d-flex flex-column align-items-center col-12 col-lg-5 text-left"
    >
      <p class="p-2 text-2xl">
        Desde Extom, estamos en continua búsqueda de personas que puedan encajar
        en nuestra filosofía corporativa, posibilitando el crecimiento conjunto
        y definiendo un plan de desarrollo profesional a través de un completo
        plan de carrera
      </p>
      <br />
      <p class="p-2 text-2xl">
        Por favor,
        <span class="text-primary font-bold"
          >rellena el siguiente formulario</span
        >
        y tendremos en cuenta tu candidatura para ocupar las posiciones que
        debamos cubrir en Extom:
      </p>
      <img
        src="/images/sobre_nosotros/trabaja_con_nosotros.jpg"
        class="col-10"
      />
    </div>

    <form
      ref="formulario"
      @submit="onSubmit($event)"
      class="p-fluid d-flex flex-wrap p-1 p-lg-2 form-tn col-12 col-lg-7"
      enctype="multipart/form-data"
    >
      <span class=" p-input-icon-left p-field mt-4 col-12 col-md-6">
        <i class="pi pi-user ml-1 mr-1"></i>
        <InputText
          id="nombre"
          name="nombre"
          type="text"
          v-model="form.nombre"
          placeholder="Nombre"
          :class="{ 'p-invalid': !isNomValid }"
          @focusout="validarNombre"
          required
        />
        <label for="nombre" class="d-none">Nombre</label>
      </span>
      <span class="p-input-icon-left p-float-label mt-4 col-12 col-md-6">
        <i class="pi pi-user ml-1 mr-1"></i>
        <InputText
          id="apellido"
          name="apellido"
          type="text"
          v-model="form.apellido"
          placeholder="Apellido"
          :class="{ 'p-invalid': !isApValid }"
          @focusout="validarApellido"
          required
        />
        <label for="apellido" class="d-none">Apellido</label>
      </span>
      <span class=" p-input-icon-left mt-4 col-12 col-md-2">
        <i class="fas fa-phone ml-1 mr-1" style="transform:rotate(90deg)" />
        <InputMask
          mask="999 99 99 99"
          name="telefono"
          :autoClear="false"
          id="telefono"
          v-model="form.telf"
          type="tel"
          placeholder="Teléfono"
          :class="{ 'p-invalid': !isTelValid }"
          @focusout="validarTelefono"
          required
        />
        <label for="telefono" class="d-none">Teléfono</label>
      </span>
      <span class=" p-input-icon-left p-float-label mt-4 col-12 col-md-6">
        <i class="far fa-envelope ml-1 mr-1"></i>
        <InputText
          id="email"
          name="email"
          type="mail"
          v-model="form.email"
          pattern="[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$"
          placeholder="Email"
          :class="{ 'p-invalid': !isEmailValid }"
          @focusout="validarEmail"
          required
        />
        <label for="email" class="d-none">Email</label>
      </span>
      <span class="p-float-label mt-4 col-12 col-md-7">
        <InputText
          id="asunto"
          name="asunto"
          type="text"
          v-model="form.asunto"
          @keydown="validarAsunto"
          placeholder="Asunto"
          required
        />
        <label for="asunto" class="ml-1 d-none">Asunto</label>
      </span>
      <span class="p-float-label mt-4 col-12 col-md-7">
        <MultiSelect
          v-model="form.puestos"
          @focusout="validarPuesto"
          :class="{ 'p-invalid': !form.puestos }"
          :options="puestos"
          optionLabel="name"
          placeholder="Elige tu puesto"
          display="chip"
          name="puesto"
          required
        />
      </span>

      <span class="p-float-label mt-4 col-12">
        <Textarea
          v-model="form.texto"
          rows="3"
          cols="20"
          name="texto"
          :autoResize="false"
          @keydown="validarTexto"
          required
        />
        <label for="asunto" class="mt-1 ml-3"
          >Texto: {{ caracteresText }} caracteres restantes</label
        >
      </span>
      <span class="p-field mt-4 col-12">
        <label for="cv" class="text-left d-block ml-2">Subir CV:</label>
        <FileUpload
          name="cv[]"
          accept=".pdf"
          :maxFileSize="1000000"
          :showUploadButton="false"
          :class="{ 'p-invalid': !form.cv }"
          ref="file"
          @select="getCV"
          @clear="delCV"
          required
        >
          <template #empty>
            <p>Arrastra y suelta tu CV</p>
          </template>
        </FileUpload>
      </span>
      <span class="p-field-checkbox col-12 mt-2">
        <Checkbox
          :class="{ 'p-invalid': !form.haConsentido }"
          :binary="true"
          name="haConsentido"
          v-model="form.haConsentido"
          required
        />
        <small for="binary" class="ml-2"
          >Acepto y consiento el tratamiento de mis datos por Extom</small
        >
      </span>
      <span class="col-10 col-md-4 mt-2 m-auto">
        <Button :loading="loading" label="Enviar" type="submit" />
      </span>
    </form>
    <!--<Toast position="top-right" class="mt-5" />-->
  </section>
</template>

<script lang="js">
import FormulariosService from '../../services/formularios.service';
  export default  {
    name: 'co-trabaja-nosotros',
    props: [],
    mounted () {

    },
    data () {
      return {
        loading: true,
        isNomValid:false,
        isApValid:true,
        isTelValid:true,
        isEmailValid:true,
        isCVValid:false,
        puestos:[
          {
            name:"Programador"
          },
          {
            name:"Consultor"
          },
          {
            name:"Analista"
          },
          {
            name:"Comercial"
          }
        ],
        form:{
          nombre:"",
          apellido:"",
          telf:null,
          email:"",
          asunto:"",
          texto:"",
          puestos:"",
          haConsentido:null,
          cv:null,
        }

      }
    },
    methods: {
      onSubmit(e){
        e.preventDefault();
        let isAllow = this.isNomValid && this.isApValid && this.isTelValid && this.isEmailValid && this.form.puestos && this.form.cv && this.form.haConsentido;
        if(!this.$refs.file.files){
          this.form.cv=null;
          isAllow=false;
        }
        if(isAllow){
          let formData = new FormData(e.target);
          let puestos ="";
          this.form.puestos.forEach((puesto)=>{
            puestos += ' - ' + puesto.name;
          });
          this.form.puestos = puestos.substring(0, 3);
          formData.append("puestos", this.form.puestos);
          formData.append("cv", this.form.cv)
          formData.append("haConsentido", this.form.haConsentido)
          FormulariosService.postTrabajaNosotros(formData).then((response)=>{
            if(response.data.success) {
              this.$toast.add({
                severity:'info',
                summary:'¡Conseguido!',
                detail:response.data.description
              })
            }else{
              this.$toast.add({
                severity:'warn',
                summary:'¡Atención!',
                detail:response.data.description
              })
            }
            this.form={
              nombre:"",
              apellido:"",
              telf:"",
              email:"",
              asunto:"",
              texto:"",
              puestos:"",
              haConsentido:false,
              cv:null,
            }
            this.isNomValid=false;
            this.isApValid=true;
            this.isTelValid=true;
            this.isEmailValid=true;
            this.isCVValid=false;
          }).catch((err)=>{
            console.log(err);
            this.$toast.add({
                severity:'error',
                summary:'Error',
                detail:'Parece que ha ocurrido un error, intentalo más tarde'
              })
            //this.$router.push('/404');
            })
        }else{
          this.$toast.removeAllGroups();
          this.$toast.add({
            severity:'warn',
            summary: 'Revise sus datos',
            detail:"Requeridos: Puesto de trabajo y CV"
          });
        }
      },
      validarTexto(){
        if(this.caracteresText <= 0){
          this.form.texto=this.form.texto.substring(0, 199)
        }

      },
      validarNombre(){
        if(this.form.nombre.length <= 1 || this.form.nombre.length >30){
          this.isNomValid = false;
        }else {this.isNomValid = true;}
      },
      validarApellido(){
        if(this.form.apellido.length <= 1 || this.form.apellido.length >30){
          this.isApValid = false;
        }else {this.isApValid = true;}
      },
      validarTelefono(){
        if(this.form.telf.length != 12 || !/[0-9]{9}$/.test(this.form.telf.replaceAll(" ", ""))){
          this.isTelValid = false;
        }else {this.isTelValid = true;}
      },
      validarEmail(){
        if(!/[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$/.test(this.form.email)){
          this.isEmailValid = false;
        }else {this.isEmailValid = true;}
      },
      validarAsunto(){
        if(this.form.asunto.length >30)
          this.form.asunto = this.form.asunto.substring(0, 29);
      },
      validarPuesto(){
        if(!this.form.puestos){
          this.isPuestoValid = false;
        }else{this.isPuestoValid = true;}
      },
      getCV(e){
        this.form.cv = e.files[0];
      },
      delCV(){
        this.form.cv=false;
      }
    },
    computed: {
      caracteresText(){
        return 200-this.form.texto.length;
      },
    }
}
</script>

<style lang="scss">
.co-trabaja-nosotros {
}
.form-tn {
  @media (min-width: 768px) {
    width: 50%;
    margin-left: auto;
  }
}
.p-inputtext:enabled:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #f8b30577;
  border-color: #f8b505;
}
.p-input-icon-left {
  width: none !important;
  @media (min-width: 568px) {
    width: inherit !important;
  }
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #f8b30575;
  border-color: #f8b505;
}
.p-checkbox .p-checkbox-box.p-highlight {
  border-color: #f8b505;
  background: #f8b505;
}
.p-checkbox:not(.p-checkbox-disabled) .p-checkbox-box.p-highlight:hover {
  border-color: #d4a52d;
  background: #d4a52d;
  color: #ffffff;
}
.p-multiselect:not(.p-disabled).p-focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: 0 0 0 0.2rem #f8b30580;
  border-color: #f8b505;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item.p-highlight {
  color: #ffffff;
  background: #f8b305d5;
}
.p-multiselect-panel .p-multiselect-items .p-multiselect-item:focus {
  outline: 0 none;
  outline-offset: 0;
  box-shadow: inset 0 0 0 0.15rem #f8b30580;
}
.p-invalid .p-fileupload-content {
  border: 1px solid;
  border-color: #dc3545;
}
</style>
