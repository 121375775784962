<template>
  <div class="about">
    <section class="about-opening">
      <div class="about-opening-hero d-flex flex-wrap">
        <img
          class="col-12 about-hero-img"
          srcset="/images/sobre_nosotros/sobre_nosotros.jpg"
          alt="Extom"
        />
      </div>
      <div class="about-opening-text container h-100">
        <div class="mt-8 row h-100 justify-content-center text-center white">
          <div
            class="col-lg-7 mt-5 mb-5 h-100 d-flex justify-content-center flex-column mb-md-0 mt-md-0"
          >
            <h1 class="text-4xl text-md-60 mb-4">
              <strong class="text-primary">Conectamos </strong>personas, información y procesos
            </h1>
            <p class="text-2xl text-md-26 mb-1">
              Somos un grupo de profesionales con amplia experiencia en la
              tecnología informática, y en la implantación y puesta en marcha de
              soluciones para las empresas.
            </p>
          </div>
        </div>
      </div>
    </section>
    <section class="about-diferentes mt-8 ">
      <div class="container">
        <div class="d-flex align-items-center flex-wrap ">
          <div class="col-12 col-lg-5">
            <img
              class="image-shadow w-100 alignnone"
              src="/images/sobre_nosotros/diferentes.jpg"
              alt="Ekon"
            />
          </div>
          <div class="col-12 col-lg-6">
            <div class="p-lg-5 text-left">
              <h2 class="text-4xl mb-4 text-4xl text-primary font-semibold">
                Qué nos hace <span class="text-900 ">diferentes</span>
              </h2>
              <p class="text-2xl">
                Extom dispone de una metodología propia que incluye una serie de
                mecanismos que hacen posible determinar la eficiencia de la
                aplicación de la tecnología en cada organización de unos
                procedimientos que garantizan y
                <strong
                  >aseguran el soporte al negocio, su puesta en marcha y la
                  formación adecuada de las personas,</strong
                >
                obteniendo de esta manera un equilibrio en el retorno de las
                inversiones realizadas.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <coTrabajaNosotros />
    <coTelFlotante/> 
    <coEmailFlotante/>
  </div>
</template>
<script>
import coTrabajaNosotros from "../components/Nosotros/coTrabajaNosotros.vue";
import coTelFlotante from '../components/coTelFlotante.vue';
import coEmailFlotante from '../components/coEmailFlotante.vue';
export default {
  components: { coTrabajaNosotros: coTrabajaNosotros, coTelFlotante,
    coEmailFlotante },
};
</script>
<style lang="scss" scoped>
.about-opening {
  position: relative;
  height: 100%;
  /*  @media (min-width: 1200px) {
    &-hero {
      position: absolute;
      z-index: -1;
      top: 0;
    }
    &-text {
      color: white;
    }
  } */
  img {
    padding: 0;
  }
}
.about-diferentes {
  background-color:#fbf6e7
}
</style>
